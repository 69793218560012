<i18n>
{
  "en": {
    "saml-step": "SAML SSO",
    "login-step": "Login",
    "api-step": "API Credentials",
    "payment-step": "Payment",
    "single-sign-on-setup": "Single Sign On Setup",
    "processing": "Processing...",
    "login-successful": "🎉 Login successful",
    "successfully-logged-in": "You have successfully connected your LINE WORKS account with LiveCall.",
    "api-credentials-successful-headline": "🎉 API Credentials successfully set up!",
    "payment-explainer": "To use LiveCall for LINE WORKS, you need to provide a method of payment. Please continue to the payment portal.",
    "open-payment-portal": "Open payment portal",
    "saml-invalid-error": "Login failed due to a configuration error. Please check your input and uploaded certificate.",
    "missing-argument-error": "Missing value for {0}.",
    "already-registered-error": "Single Sign On is already configured for this LINE WORKS Group, please {0} instead.",
    "log-in": "log in",
    "general-registration-error": "An unknown error occurred during sign on."
  },
  "ja": {
    "saml-step": "SAML SSO",
    "login-step": "ログイン",
    "api-step": "API 認証情報",
    "payment-step": "支払い",
    "single-sign-on-setup": "シングルサインオン(SSO)の設定",
    "processing": "進行中…",
    "login-successful": "🎉 ログインに成功しました",
    "successfully-logged-in": "LINE WORKSアカウントとLiveCallの接続に成功しました。",
    "api-credentials-successful-headline": "🎉 API認証情報が正常に設定されました！",
    "payment-explainer": "LiveCall for LINE WORKSを使用するには、支払い方法を設定する必要があります。支払いポータルに進んでください。",
    "open-payment-portal": "支払いポータルを開く",
    "saml-invalid-error": "ログインに失敗しました。入力内容、アップロードファイルを再度ご確認ください。",
    "missing-argument-error": "{0}が未記入です。再度ご確認ください。",
    "already-registered-error": "このLINEWORKSグループにはシングルサインオン(SSO)が既に設定されています。代わりに{0}してください。",
    "log-in": "ログイン",
    "general-registration-error": "サインオン中に不明なエラーが発生しました。"
  }
}
</i18n>

<template>
  <v-container>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">{{ $t('saml-step') }}</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">{{ $t('login-step') }}</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3">{{ $t('api-step') }}</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4">{{ $t('payment-step') }}</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <h2 class="mb-5">{{ $t('single-sign-on-setup') }}</h2>

          <v-alert v-if="error" type="error" elevation="2">
            <i18n :path="error">
              <span v-if="error === 'missing-argument-error'">{{ missingArgument }}</span>
              <router-link v-if="error === 'already-registered-error'" to="/">{{ $t('log-in') }}</router-link>
            </i18n>
          </v-alert>

          <SetupSaml :next-url="nextUrl" :fail-url="failUrl"></SetupSaml>
        </v-stepper-content>

        <v-stepper-content step="2">
          <h2>{{ $t('processing') }}</h2>
          <v-progress-linear indeterminate></v-progress-linear>
        </v-stepper-content>

        <v-stepper-content step="3">
          <h2>{{ $t('login-successful') }}</h2>

          <p>{{ $t('successfully-logged-in') }}</p>

          <SetupApi @success="advanceToStep(4)"></SetupApi>
        </v-stepper-content>

        <v-stepper-content step="4">
          <h2>{{ $t('api-credentials-successful-headline') }}</h2>

          <p>{{ $t('payment-explainer') }}</p>

          <v-form method="POST" :action="paymentPortalUri" @submit.passive="working = true">
            <input type="hidden" name="next" :value="portalReturnUri">
            <v-btn type="submit" color="primary"
                   :disabled="working"
                   :loading="working">
              {{ $t('open-payment-portal') }}
              <v-icon right>mdi-open-in-new</v-icon>
            </v-btn>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
export default {
  name: 'Setup',
  props: {
    samlToken: {
      type: String,
      required: false
    },
    initialStep: {
      type: Number,
      required: false
    },
    fail: {
      type: Boolean,
      required: false
    }
  },
  components: {
    SetupSaml: () => import('@/components/SetupSaml'),
    SetupApi: () => import('@/components/SetupApi')
  },
  data: () => ({
    step: 1,
    working: false,
    error: null
  }),
  computed: {
    nextUrl() {
      const url = new URL(window.location);
      url.pathname = '/setup/2';
      return url.toString();
    },
    failUrl() {
      const url = new URL(window.location);
      url.pathname = '/setup/1';
      url.search = '?fail';
      return url.toString();
    },
    paymentPortalUri() {
      return `${this.$api.base}/payments/portal?jwt=${this.$api.token}`;
    },
    portalReturnUri() {
      const url = new URL(window.location);
      url.pathname = '/dashboard';
      url.search = '';
      url.hash = '';
      return url.toString();
    },
    missingArgument() {
      return this.$route.query.missing;
    }
  },
  beforeMount() {
    if (this.initialStep) {
      this.step = this.initialStep;
    }

    if (this.samlToken) {
      this.processSAMLLogin();
    }

    if (this.fail && this.step === 1) {
      if (this.$route.query.invalid === 'saml') {
        this.error = 'saml-invalid-error';
      } else if ('missing' in this.$route.query) {
        this.error = 'missing-argument-error';
      } else if (this.$route.query.error === 'already-registered') {
        this.error = 'already-registered-error';
      } else {
        this.error = 'general-registration-error';
      }
    }
  },
  methods: {
    async processSAMLLogin() {
      this.$api.setToken(this.samlToken);
      /** @type {Response} */
      let response = await this.$api.fetch('/auth/saml-token', { method: 'POST' });

      if (!response.ok) {
        this.step = 1;
        return;
      }

      const tokens = await response.json();
      this.$api.setToken(tokens['access_token']);

      response = await this.$api.fetch('/admin/signup', { method: 'POST' });
      const result = await response.json();
      const next = result.next && result.next.toLowerCase();

      if (!next) {
        await this.$router.push({ name: 'Dashboard' });
      } else if (next === 'api') {
        await this.advanceToStep(3)
      } else {
        throw new Error(`Unexpected next state ${next}`)
      }
    },
    async advanceToStep(number) {
      this.step = number;
      await this.$router.push({ name: 'Setup', params: { step: number.toString() } })
    }
  }
}
</script>
